const Logo = () => {
    return (
        <svg viewBox="0 0 560 127" xmlns="http://www.w3.org/2000/svg" >
            <path d="M53,1.2v16.7H36.2v108.3h-17V17.8H0V1.2H53z"/>
            <path d="M96.6,126.1l-2.7-26.9H74.1l-2.7,26.9H53.9l13.8-125h32.6l13.8,125H96.6z M75.7,82.6h16.7l-4.1-40.8L86,20.2h-4.4l-1.9,21.6L75.7,82.6z"/>
            <path d="M176.3,1.2v125h-52.4V1.2H176.3z M140.9,109.5h18.4V17.8h-18.4V109.5z"/>
            <path d="M189.5,1.2h17v108.3h25.7v16.7h-42.7V1.2z"/>
            <path d="M257.9,71.2v38.3h25v16.7h-42V1.2h42l-0.5,16.7h-24.5v36.7h18.4l-0.9,16.7H257.9z"/>
            <path d="M370.4,42.5l-17,0V17.8h-16.7v91.6h16.7V81.6l17-0.1v44.6h-50.7V1.2h50.7V42.5z"/>
            <path d="M415.6,1.2h17v125h-17V71.4l-17.3,0v54.7h-17V1.2h17v53.3l17.3-0.1V1.2z"/>
            <path d="M464.8,71.2v38.3h25v16.7h-42V1.2h42l-0.5,16.7h-24.5v36.7h18.4l-0.9,16.7H464.8z"/>
            <path d="M519.9,35.2l-1.5-12.6h-0.7v103.5h-17V1.2H529l14.8,89.9l1.5,12.6h0.7V1.2l17,0v124.9l-27.9,0L519.9,35.2z"/>
        </svg>
    );
}

export default Logo;